const openLink = (link: string) => {
	let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

	if (isSafari) {
		window.location.href = link
	} else {
		let win = window.open(link, '_blank')
		win.focus()
	}
}
export { openLink }
